var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: { drawer: _vm.drawer, withHeader: false, size: _vm.size },
          on: { close: _vm.close },
        },
        [
          _c("div", { staticClass: "view" }, [
            !_vm.showTrack && !_vm.showIsf && !_vm.showOrderDetail
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "tag" },
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { type: "card" },
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.active,
                            callback: function ($$v) {
                              _vm.active = $$v
                            },
                            expression: "active",
                          },
                        },
                        [
                          _c("el-tab-pane", {
                            attrs: { label: "基本信息", name: "0" },
                          }),
                          _vm.rowData.status !== 0 &&
                          _vm.mblDetail.clearenceType
                            ? _c("el-tab-pane", {
                                attrs: { label: "清关", name: "1" },
                              })
                            : _vm._e(),
                          _vm.rowData.status !== 0 &&
                          _vm.rowData.status !== 1 &&
                          _vm.mblDetail.pickUpType
                            ? _c("el-tab-pane", {
                                attrs: { label: "提拆交", name: "2" },
                              })
                            : _vm._e(),
                          _c("el-tab-pane", {
                            attrs: { label: "订单信息", name: "3" },
                          }),
                        ],
                        1
                      ),
                      _c("i", {
                        staticClass: "el-icon-close closeIcon",
                        on: { click: _vm.close },
                      }),
                    ],
                    1
                  ),
                  _vm.active === "0"
                    ? _c("div", { staticClass: "panel panelActive1" }, [
                        _c(
                          "div",
                          { staticClass: "panel2" },
                          [
                            !_vm.billType
                              ? _c(
                                  "div",
                                  { staticClass: "panel-title" },
                                  [
                                    _c("svg-icon", {
                                      style: { "font-size": 20 },
                                      attrs: {
                                        iconClass: "panel-icon",
                                        className: "icon",
                                      },
                                    }),
                                    _vm._v(" 基本信息 "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.billType ? "航空公司" : "船公司"
                                        )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.mblDetail.transportName)
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("主单号")]),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: 155,
                                            expression: "155",
                                          },
                                        ],
                                      },
                                      [_vm._v(_vm._s(_vm.mblDetail.mblNo))]
                                    ),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("目的国家")]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.mblDetail.countryCode)),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("ETD")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFilete")(
                                            _vm.mblDetail.etd
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("ETA")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFilete")(
                                            _vm.mblDetail.eta
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("ATD")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFilete")(
                                            _vm.mblDetail.atd
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("ATA")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFilete")(
                                            _vm.mblDetail.ata
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.billType ? "始发机场" : "始发地"
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: 155,
                                            expression: "155",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.mblDetail.originHarborName)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.billType ? "目的机场" : "目的地"
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: 155,
                                            expression: "155",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.mblDetail.destinationHarborName
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("清关方式")]),
                                    _c(
                                      "p",
                                      { staticStyle: { color: "#2395f3" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "type",
                                            staticStyle: {
                                              background:
                                                "rgba(35, 149, 243, 0.1)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mblDetail.clearenceType
                                                    ? "平台清"
                                                    : "自清"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.mblDetail.clearenceType
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mblDetail.clearenceSupply
                                                    ?.name
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("提货方式")]),
                                    _c(
                                      "p",
                                      { staticStyle: { color: "#9d67fe" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "type",
                                            staticStyle: {
                                              background:
                                                "rgba(157, 103, 254, 0.1)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mblDetail.pickUpType
                                                    ? _vm.mblDetail
                                                        .pickUpType === 1
                                                      ? "直送"
                                                      : "回仓"
                                                    : "自提"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.mblDetail.pickUpType
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mblDetail.pickUpSupply
                                                    ?.name
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("重量")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.mblDetail.weightKgs) + " KG"
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("方数")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.mblDetail.weightCbm) + " CBM"
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("提单件数")]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.mblDetail.qty)),
                                    ]),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("订单件数")]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.mblDetail.btns)),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm.mblDetail?.pickUp?.clearenceSupplierName
                              ? _c("el-row", [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("清关供应商")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mblDetail?.pickUp
                                            ?.clearenceSupplierName
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.mblDetail?.pickUp?.pickUpSupplierName
                              ? _c("el-row", [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("提货供应商")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mblDetail?.pickUp
                                            ?.pickUpSupplierName
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("发货人/Shipper")]),
                                    _c(
                                      "div",
                                      { staticClass: "field-content" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field-c_top" },
                                          [
                                            _vm.shipperNameOptions?.companyName
                                              ? _c(
                                                  "div",
                                                  { staticClass: "field3" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("公司名"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.shipperNameOptions
                                                            ?.companyName
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "field-c_top" },
                                          [
                                            _vm.shipperNameOptions?.taxNumber
                                              ? _c(
                                                  "div",
                                                  { staticClass: "field3" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("税号"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.shipperNameOptions
                                                            ?.taxNumber
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "field-c_bottom" },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.shipperNameOptions
                                                        ?.addressLine1
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.shipperNameOptions
                                                        ?.addressLine2
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.shipperNameOptions
                                                        ?.state
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.shipperNameOptions
                                                        ?.city
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.shipperNameOptions
                                                        ?.countryCode
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.shipperNameOptions
                                                      ?.postalCode
                                                      ? _vm.shipperNameOptions
                                                          ?.postalCode
                                                      : ""
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("收货人/Consignee")]),
                                    _c(
                                      "div",
                                      { staticClass: "field-content" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field-c_top" },
                                          [
                                            _vm.consigneeNameOptions
                                              ?.companyName
                                              ? _c(
                                                  "div",
                                                  { staticClass: "field3" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("公司名"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .consigneeNameOptions
                                                            ?.companyName
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "field-c_top" },
                                          [
                                            _vm.consigneeNameOptions?.taxNumber
                                              ? _c(
                                                  "div",
                                                  { staticClass: "field3" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("税号"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .consigneeNameOptions
                                                            ?.taxNumber
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "field-c_bottom" },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.consigneeNameOptions
                                                        ?.addressLine1
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.consigneeNameOptions
                                                        ?.addressLine2
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.consigneeNameOptions
                                                        ?.state
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.consigneeNameOptions
                                                        ?.city
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formatStatusAddress"
                                                    )(
                                                      _vm.consigneeNameOptions
                                                        ?.countryCode
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.consigneeNameOptions
                                                      ?.postalCode
                                                      ? _vm.consigneeNameOptions
                                                          ?.postalCode
                                                      : ""
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("提单类型")]),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.mblDetail.classification
                                              ? "分单"
                                              : "直单"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("提单文件")]),
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "check_file",
                                          on: { click: _vm.checkBillFile },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-search",
                                          }),
                                          _vm._v("查看提单文件 "),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("提单状态")]),
                                    _c("p", [
                                      _c(
                                        "span",
                                        { staticClass: "bill-status" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.config.billShipSubtabOptions[
                                                _vm.mblDetail.status
                                              ]?.find(
                                                (a) =>
                                                  a.value ==
                                                  _vm.mblDetail.subStatus
                                              )?.label
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("div", { staticClass: "field" }, [
                                    _c("label", [_vm._v("确认时间")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFilete")(
                                            _vm.mblDetail.entryDate
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.billType
                          ? _c(
                              "div",
                              { staticClass: "panel2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "panel-title" },
                                  [
                                    _c("svg-icon", {
                                      style: { "font-size": 20 },
                                      attrs: {
                                        iconClass: "panel-icon",
                                        className: "icon",
                                      },
                                    }),
                                    _vm._v(" 柜子信息 "),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c("div", { staticClass: "field" }, [
                                        _c("label", [_vm._v("柜号")]),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: 155,
                                                expression: "155",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.mblDetail.container
                                                  ?.containerNumber
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c("div", { staticClass: "field" }, [
                                        _c("label", [_vm._v("柜型")]),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: 155,
                                                expression: "155",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.mblDetail.container
                                                  ?.containertype
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c("div", { staticClass: "field" }, [
                                        _c("label", [_vm._v("船名")]),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: 155,
                                                expression: "155",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.mblDetail.container
                                                  ?.vesselName
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c("div", { staticClass: "field" }, [
                                        _c("label", [_vm._v("船次/航次")]),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: 155,
                                                expression: "155",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.mblDetail.container
                                                  ?.voyageNo
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c("div", { staticClass: "field" }, [
                                        _c("label", [_vm._v("航线")]),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: 155,
                                                expression: "155",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.mblDetail.container
                                                  ?.routeLine
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c("div", { staticClass: "field" }, [
                                        _c("label", [_vm._v("封条号")]),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: 155,
                                                expression: "155",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.mblDetail.container
                                                  ?.sealNumber
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c("div", { staticClass: "field" }, [
                                        _c("label", [_vm._v("卸船日期")]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFilete")(
                                                _vm.mblDetail.container
                                                  ?.dischargeDate
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c("div", { staticClass: "field" }, [
                                        _c("label", [_vm._v("可提柜日期")]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFilete")(
                                                _vm.mblDetail.container
                                                  ?.availableDate
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c("div", { staticClass: "field" }, [
                                        _c("label", [_vm._v("还空柜日期")]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFilete")(
                                                _vm.mblDetail.container
                                                  ?.emptyReturnedDate
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.mblDetail.status !== 0
                          ? _c(
                              "div",
                              {
                                staticClass: "panel-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.viewDetails("track")
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/bill/track.png"),
                                  },
                                }),
                                _vm._v(" 提单轨迹 "),
                              ]
                            )
                          : _vm.mblDetail.clearenceType !== 0 &&
                            _vm.mblDetail.status === 0 &&
                            _vm.billType === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "panel-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.viewDetails("isf")
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/bill/isf.png"),
                                  },
                                }),
                                _vm._v(" ISF信息 "),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.active === "1"
                    ? _c(
                        "div",
                        { staticClass: "panel panelActive2" },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c("div", { staticClass: "field" }, [
                                  _c("label", [_vm._v("接收DO邮箱")]),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: 190,
                                          expression: "190",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mblDetail?.clearence
                                            ?.receivedDoEmail
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c("div", { staticClass: "field" }, [
                                  _c("label", [_vm._v("接收DO公司名称")]),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: 190,
                                          expression: "190",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mblDetail?.clearence
                                            ?.receivedDoCompany
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c("div", { staticClass: "field" }, [
                                  _c("label", [_vm._v("放行时间")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.mblDetail?.clearence?.releaseDate
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c("div", { staticClass: "field" }, [
                                  _c("label", [_vm._v("是否查验")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.mblDetail?.clearence?.isHold
                                          ? "是"
                                          : "否"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "field" }, [
                                  _c("label", [_vm._v("查验原因")]),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: 510,
                                          expression: "510",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mblDetail?.clearence?.holdReason
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "field" }, [
                                  _c("label", [_vm._v("DO")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFilete")(
                                          _vm.mblDetail?.clearence?.doDate
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "field" }, [
                                  _c("label", [_vm._v("AN")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFilete")(
                                          _vm.mblDetail?.clearence?.anDate
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm.billType === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "panel-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewDetails("isf")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/bill/isf.png"),
                                    },
                                  }),
                                  _vm._v(" ISF信息 "),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.active === "2"
                    ? _c("div", { staticClass: "panel panelActive3" }, [
                        _c("div", { staticClass: "panel2" }, [
                          _c(
                            "div",
                            { staticClass: "panel-title" },
                            [
                              _c("svg-icon", {
                                style: { "font-size": 20 },
                                attrs: {
                                  iconClass: "panel-icon",
                                  className: "icon",
                                },
                              }),
                              _vm._v(" 提货信息 "),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "panle-container" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("预约提货时间")]),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFilete")(
                                                _vm.mblDetail?.pickUp
                                                  ?.picExpactPickUpDate
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("提货时间")]),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateFilete")(
                                              _vm.mblDetail?.pickUp
                                                ?.picPickUpDate
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("状态")]),
                                      _c("p", [
                                        _c("span", { staticClass: "status" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.config.billShipSubtabOptions[
                                                _vm.mblDetail.status
                                              ]?.find(
                                                (a) =>
                                                  a.value ==
                                                  _vm.mblDetail.subStatus
                                              )?.label
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "panel2" }, [
                          _c(
                            "div",
                            { staticClass: "panel-title" },
                            [
                              _c("svg-icon", {
                                style: { "font-size": 20 },
                                attrs: {
                                  iconClass: "panel-icon",
                                  className: "icon",
                                },
                              }),
                              _vm._v(" 到仓信息 "),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "panle-container" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("到仓时间")]),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFilete")(
                                                _vm.mblDetail?.pickUp
                                                  ?.picArrivedDate
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("首次扫描时间")]),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFilete")(
                                                _vm.mblDetail?.pickUp
                                                  ?.picFirstArrivedScanDate
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("到仓件数")]),
                                      _c("p", [
                                        _c("span", { staticClass: "markbox" }, [
                                          _c(
                                            "span",
                                            { staticClass: "mark-label green" },
                                            [_vm._v("已到")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "mark-info" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mblDetail?.pickUp
                                                    ?.arrivedQty
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("span", { staticClass: "markbox" }, [
                                          _c(
                                            "span",
                                            { staticClass: "mark-label red" },
                                            [_vm._v("未到")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "mark-info" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mblDetail?.pickUp
                                                    ?.notArrivedQty
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("扫描完成时间")]),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFilete")(
                                                _vm.mblDetail?.pickUp
                                                  ?.picLastArrivedScanDate
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "panel2" }, [
                          _c(
                            "div",
                            { staticClass: "panel-title" },
                            [
                              _c("svg-icon", {
                                style: { "font-size": 20 },
                                attrs: {
                                  iconClass: "panel-icon",
                                  className: "icon",
                                },
                              }),
                              _vm._v(" 出仓信息 "),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "panle-container" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("出仓板数")]),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.mblDetail?.pickUp
                                              ?.notDepaturedQty
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("首次扫描时间")]),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFilete")(
                                                _vm.mblDetail?.pickUp
                                                  ?.picFirstDepaturedScanDate
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("出仓件数")]),
                                      _c("p", [
                                        _c("span", { staticClass: "markbox" }, [
                                          _c(
                                            "span",
                                            { staticClass: "mark-label green" },
                                            [_vm._v("已出")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "mark-info" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mblDetail?.pickUp
                                                    ?.depaturedQty
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("span", { staticClass: "markbox" }, [
                                          _c(
                                            "span",
                                            { staticClass: "mark-label red" },
                                            [_vm._v("未出")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "mark-info" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mblDetail?.pickUp
                                                    ?.notDepaturedQty
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", [_vm._v("扫描完成时间")]),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFilete")(
                                                _vm.mblDetail?.pickUp
                                                  ?.picLastDepaturedcanDate
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "panel-title" },
                            [
                              _c("svg-icon", {
                                style: { "font-size": 20 },
                                attrs: {
                                  iconClass: "panel-icon",
                                  className: "icon",
                                },
                              }),
                              _vm._v(" 派送方式 "),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "panle-container panel3" },
                            [
                              _c("BaseTable", {
                                attrs: {
                                  height: 280,
                                  tableData: _vm.tableData1,
                                  isPager: false,
                                  border: true,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "opt",
                                      fn: function (row) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "table-opt" },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "left",
                                                    width: "300",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "view-detail",
                                                    },
                                                    _vm._l(
                                                      row.scope.eventItems,
                                                      function (item, $index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: $index,
                                                            staticClass:
                                                              "eventItems",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.eventDate
                                                                )
                                                              ),
                                                            ]),
                                                            _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.title
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    item.description
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _c("img", {
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      src: require("@/assets/images/bill/detail.png"),
                                                    },
                                                    slot: "reference",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "remarks",
                                      fn: function (row) {
                                        return [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "bottom-end",
                                                width: "440",
                                                trigger: "hover",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "remarks-box" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "remarks-header",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("备注内容"),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleCopy(
                                                                row.scope
                                                                  .remarks
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/images/order/copy3.png"),
                                                            },
                                                          }),
                                                          _vm._v("复制"),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "remarks-body",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.scope.remarks
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("img", {
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                                attrs: {
                                                  slot: "reference",
                                                  src: require("@/assets/images/bill/detail.png"),
                                                },
                                                slot: "reference",
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3926969357
                                ),
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.active === "3"
                    ? _c(
                        "div",
                        { staticClass: "panel panelActive4" },
                        [
                          _c("BaseTable", {
                            attrs: {
                              height: 600,
                              tableData: _vm.tableData2,
                              pageIndex: _vm.pager2.pageIndex,
                              pageSize: _vm.pager2.pageSize,
                              isPager: true,
                              border: true,
                            },
                            on: { handlePageChange: _vm.handlePageChange },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "opt",
                                  fn: function (row) {
                                    return [
                                      row.scope.expressSupplier !== "Other"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "opt-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.detail(row.scope)
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/bill/detail.png"),
                                                  alt: "",
                                                },
                                              }),
                                              _vm._v(" 详情 "),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3174200202
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.showTrack || _vm.showIsf || _vm.showOrderDetail
              ? _c("div", { staticClass: "back" }, [
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "before", on: { click: _vm.back } },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/bill/back.png"),
                            alt: "",
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.showOrderDetail ? "返回订单详情" : "返回详情"
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "current" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/bill/arrow.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.showIsf && "ISF信息") ||
                              (_vm.showTrack && "提单轨迹") ||
                              (_vm.showOrderDetail && "订单详情")
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-close closeIcon",
                    on: { click: _vm.close },
                  }),
                ])
              : _vm._e(),
            _vm.showTrack
              ? _c("div", { staticClass: "log-body" }, [
                  _c("div", { staticClass: "track-num" }, [
                    _vm._v(" 提单号："),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(_vm._s(_vm.mblDetail.blNo)),
                    ]),
                  ]),
                  _c("div", { staticClass: "track-other" }, [
                    _c("div", { staticClass: "country" }, [
                      _c("div", { staticClass: "country-code" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.mblDetail.originHarborName.split("-")[0])
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.mblDetail.originHarborName.split("-")[1])
                          ),
                        ]),
                      ]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/order/guide.png"),
                        },
                      }),
                      _c("div", { staticClass: "country-code" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.mblDetail.destinationHarborName.split("-")[0]
                            )
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.mblDetail.destinationHarborName.split("-")[1]
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "navication flex_c_c" }, [
                      _vm._v(" 当前状态："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.config.billShipSubtabOptions[
                              _vm.mblDetail.status
                            ]?.find((a) => a.value == _vm.mblDetail.subStatus)
                              ?.label
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "track-step" },
                    _vm._l(_vm.trackList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "step-item" },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                "step-title",
                                index === 0
                                  ? "blue"
                                  : item.descriptionCH.indexOf("海关查验") >
                                      -1 || item.statusCode === "HOLD_SYS"
                                  ? "check"
                                  : "",
                              ],
                            },
                            [
                              _c("div", { staticClass: "dot" }, [
                                _c("div", {
                                  class: [
                                    "step-dot",
                                    index === 0
                                      ? "blue-dot"
                                      : item.descriptionCH.indexOf("海关查验") >
                                          -1 || item.statusCode === "HOLD_SYS"
                                      ? "check-dot"
                                      : "",
                                  ],
                                }),
                              ]),
                              _c("div", { staticClass: "step-time" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.eventDate) +
                                    "  " +
                                    _vm._s(item.location) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              class: [
                                "step-info",
                                index === 0
                                  ? "blue"
                                  : item.descriptionCH.indexOf("海关查验") >
                                      -1 || item.statusCode === "HOLD_SYS"
                                  ? "check"
                                  : "",
                              ],
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.descriptionCH))]),
                              item.isOpen && item.outboundDetails
                                ? _vm._l(item.outboundDetails, function (a, i) {
                                    return _c(
                                      "div",
                                      { key: i, staticClass: "step-detail" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "step-detail_item" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "detail-label1" },
                                              [_vm._v("派送方式")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "detail-info" },
                                              [_vm._v(_vm._s(a?.typeName))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 10 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "step-detail_item",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail-label1",
                                                      },
                                                      [_vm._v("总件数")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail-info",
                                                      },
                                                      [_vm._v(_vm._s(a?.qty))]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 14 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "step-detail_item",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail-label2",
                                                      },
                                                      [_vm._v("ISP")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail-info",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            a?.ispFile
                                                              ?.uploadDate
                                                              ? a?.ispFile
                                                                  ?.uploadDate
                                                              : "无"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    a?.ispFile?.fileGuid
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "viewFile flex_c_c",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewFile(
                                                                  a?.ispFile
                                                                    ?.fileGuid
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("查看")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 10 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "step-detail_item",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail-label1",
                                                      },
                                                      [_vm._v("出仓")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail-info",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            a?.departureQty
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 14 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "step-detail_item",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail-label2",
                                                      },
                                                      [_vm._v("POD")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail-info",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            a?.podFile
                                                              ?.uploadDate
                                                              ? a?.podFile
                                                                  ?.uploadDate
                                                              : "无"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    a?.podFile?.fileGuid
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "viewFile flex_c_c",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewFile(
                                                                  a?.podFile
                                                                    ?.fileGuid
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("查看")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                          index !== _vm.trackList.length - 1
                            ? _c("div", {
                                class: [
                                  "step-line",
                                  ,
                                  index === 0 ? "blue-line" : "",
                                ],
                              })
                            : _vm._e(),
                          item.outboundDetails && item.outboundDetails.length
                            ? _c("i", {
                                class: [
                                  item.isOpen
                                    ? "el-icon-arrow-up"
                                    : "el-icon-arrow-down",
                                  "arrow",
                                ],
                                attrs: { title: item.isOpen ? "收起" : "展开" },
                                on: {
                                  click: function ($event) {
                                    return _vm.isOepn(index)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm.showIsf
              ? _c("div", { staticClass: "log-body" }, [
                  _c(
                    "div",
                    { staticClass: "isf_info" },
                    [
                      _c("div", { staticClass: "row-item" }, [
                        _c("div", { staticClass: "item-label" }, [
                          _vm._v("OnBoard Date"),
                        ]),
                        _c("div", { staticClass: "item-data" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFilete")(
                                  _vm.mblDetail?.clearence?.isf?.onBoardDate
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-item" }, [
                        _c("div", { staticClass: "item-label" }, [
                          _vm._v("AMS SCAC"),
                        ]),
                        _c("div", { staticClass: "item-data" }, [
                          _vm._v(
                            _vm._s(_vm.mblDetail?.clearence?.isf?.amsscac)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-item" }, [
                        _c("div", { staticClass: "item-label" }, [
                          _vm._v("AMS No."),
                        ]),
                        _c("div", { staticClass: "item-data" }, [
                          _vm._v(
                            _vm._s(_vm.mblDetail?.clearence?.isf?.amsblNumber)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-item" }, [
                        _c("div", { staticClass: "item-label" }, [
                          _vm._v("Importer Bond Type"),
                        ]),
                        _c("div", { staticClass: "item-data" }, [
                          _vm._v(
                            _vm._s(
                              _vm.config.bondTypeOptions.find(
                                (a) =>
                                  a.value ===
                                  _vm.mblDetail?.clearence?.isf?.bondType
                              )?.label
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-item" }, [
                        _c("div", { staticClass: "item-label" }, [
                          _vm._v("Bill Number Type"),
                        ]),
                        _c("div", { staticClass: "item-data" }, [
                          _vm._v(
                            _vm._s(
                              _vm.mblDetail?.clearence?.isf?.classification
                                ? "分单"
                                : "直单"
                            )
                          ),
                        ]),
                      ]),
                      _vm._l(_vm.addressRowArr, function (item, $index) {
                        return _c(
                          "div",
                          { key: $index, staticClass: "row-item" },
                          [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v(_vm._s(item.label1)),
                              _c("br"),
                              _vm._v(_vm._s(item.nameCH)),
                            ]),
                            _c("div", { staticClass: "address" }, [
                              item.label1 !== "Importer"
                                ? _c("div", { staticClass: "conpanyName" }, [
                                    _c("span", [_vm._v("公司名")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mblDetail?.clearence?.isf?.[
                                            item.label2
                                          ]
                                        )
                                      ),
                                    ]),
                                  ])
                                : _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "conpanyName" },
                                        [
                                          _c("span", [_vm._v("公司名")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.mblDetail?.clearence?.isf?.[
                                                  item.label2
                                                ]
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "conpanyName" },
                                        [
                                          _c("span", [_vm._v("EIN")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.addressDetail[
                                                  item.label2 + "Address"
                                                ]?.taxNumber
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatStatusAddress")(
                                        _vm.addressDetail[
                                          item.label2 + "Address"
                                        ]?.addressLine1
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("formatStatusAddress")(
                                        _vm.addressDetail[
                                          item.label2 + "Address"
                                        ]?.addressLine2
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("formatStatusAddress")(
                                        _vm.addressDetail[
                                          item.label2 + "Address"
                                        ]?.city
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("formatStatusAddress")(
                                        _vm.addressDetail[
                                          item.label2 + "Address"
                                        ]?.state
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("formatStatusAddress")(
                                        _vm.addressDetail[
                                          item.label2 + "Address"
                                        ]?.countryCode
                                      )
                                    ) +
                                    " "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.addressDetail[item.label2 + "Address"]
                                        ?.postalCode
                                        ? _vm.addressDetail[
                                            item.label2 + "Address"
                                          ]?.postalCode
                                        : ""
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "panle-container" },
                    [
                      _c("div", { staticClass: "table-header" }, [
                        _vm._v("+HS CODE列表"),
                      ]),
                      _c("BaseTable", {
                        attrs: {
                          height: "133",
                          tableData: _vm.tableData3,
                          isPager: false,
                          border: true,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.showOrderDetail
              ? _c("div", { staticClass: "log-body orderActive" }, [
                  _c(
                    "div",
                    { staticClass: "panle-container3" },
                    [
                      _c("div", { staticClass: "panle-title" }, [
                        _vm._v("基本信息"),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("订单编号")]),
                              _c("p", [_vm._v(_vm._s(_vm.order.id))]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("客户单号")]),
                              _c(
                                "p",
                                {
                                  staticClass: "orderNo",
                                  staticStyle: { color: "#2395f3" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.order.referenceNumber)),
                                  ]),
                                  _c("img", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      src: require("@/assets/images/order/copy2.png"),
                                      title: "复制",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCopy(
                                          _vm.order.referenceNumber
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("追踪单号")]),
                              _c(
                                "p",
                                {
                                  staticClass: "orderNo",
                                  staticStyle: { color: "#2395f3" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.order.trackingNumber)),
                                  ]),
                                  _c("img", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      src: require("@/assets/images/order/copy2.png"),
                                      title: "复制",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCopy(
                                          _vm.order.trackingNumber
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("供应商")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$store.state.supply)),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("件数")]),
                              _c("p", [_vm._v(_vm._s(_vm.order.qty))]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("状态")]),
                              _c("span", { staticClass: "order-status" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatStatus")(_vm.order.status)
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("实重")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.order.actWeightKG) + " KG"),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("材积重")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.order.volumeWeightKG) + " KG"
                                ),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("计费重")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.order.weightKG) + " KG"),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("费用")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.order.estimateAmount) +
                                    " " +
                                    _vm._s(_vm.order.currency)
                                ),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("国家")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatterCountryCode(
                                      _vm.order.originCountryCode
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("派送方式")]),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    color: "#2395f3",
                                    "font-weight": "bold",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.order.expressSupplier) +
                                      " " +
                                      _vm._s(_vm.order.expressService)
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("创建时间")]),
                              _c("p", [_vm._v(_vm._s(_vm.order.createdOn))]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("上传时间")]),
                              _c("p", [_vm._v(_vm._s(_vm.order.uploadOn))]),
                            ]),
                          ]),
                          _vm.order.fbaId || _vm.order.fbaRefNo
                            ? _c("el-col", { attrs: { span: 8 } }, [
                                _c("div", { staticClass: "field" }, [
                                  _c("label", [_vm._v("FBA信息")]),
                                  _c("p", [
                                    _vm.order.fbaId
                                      ? _c("span", [
                                          _vm._v(_vm._s(_vm.order.fbaId)),
                                        ])
                                      : _vm._e(),
                                    _vm.order.fbaId && _vm.order.fbaRefNo
                                      ? _c(
                                          "span",
                                          { staticStyle: { margin: "0 10px" } },
                                          [_vm._v("|")]
                                        )
                                      : _vm._e(),
                                    _vm.order.fbaRefNo
                                      ? _c("span", [
                                          _vm._v(_vm._s(_vm.order.fbaRefNo)),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "panle-container3" },
                    [
                      _c("div", { staticClass: "panle-title" }, [
                        _vm._v("收件方信息"),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("公司名称")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.order?.address?.companyName)),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 16 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("收件人")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.order?.address?.attentionName)
                                ),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("类型")]),
                              _c("p", [_vm._v("收件人")]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("国家")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatterCountryCode(
                                      _vm.order?.address?.countryCode
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("省州")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.order?.address?.state)),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("城市")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.order?.address?.city)),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("邮编")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.order?.address?.postalCode)),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("电话")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.order?.address?.tel)),
                              ]),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("邮箱")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.order?.address?.email)),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("详细地址一")]),
                              _c("p", [
                                _c("span", { staticClass: "label flex_c_c" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.order?.address?.classificationName
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.order?.address?.addressLine1) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "field2" }, [
                              _c("label", [_vm._v("详细地址二")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.order?.address?.addressLine2)
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "type2" }, [
                    _c(
                      "div",
                      {
                        class: { active: _vm.orderActive === 0, btn: true },
                        on: {
                          click: function ($event) {
                            return _vm.changeType(0)
                          },
                        },
                      },
                      [_vm._v(" 包裹信息 ")]
                    ),
                    _c(
                      "div",
                      {
                        class: { active: _vm.orderActive === 1, btn: true },
                        on: {
                          click: function ($event) {
                            return _vm.changeType(1)
                          },
                        },
                      },
                      [_vm._v(" 产品信息 ")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "data2" },
                    [
                      _c("BaseTable", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.orderActive,
                            expression: "orderActive",
                          },
                        ],
                        attrs: {
                          height: "320",
                          tableData: _vm.tableData5,
                          pageIndex: _vm.pager2.pageIndex,
                          pageSize: _vm.pager2.pageSize,
                          loading: _vm.loading,
                          isPager: true,
                        },
                        on: { handlePageChange: _vm.handlePageChange },
                      }),
                      _c("BaseTable", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.orderActive,
                            expression: "!orderActive",
                          },
                        ],
                        attrs: {
                          height: "320",
                          tableData: _vm.tableData4,
                          pageIndex: _vm.pager2.pageIndex,
                          pageSize: _vm.pager2.pageSize,
                          loading: _vm.loading,
                          isPager: true,
                        },
                        on: { handlePageChange: _vm.handlePageChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "productURL",
                              fn: function (row) {
                                return [
                                  _c("i", {
                                    staticClass: "el-icon-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openUrl(row.scope.productURL)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2587786495
                        ),
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c("UploadAttachmentDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          diaLogType: 1,
          rowData: _vm.rowData,
        },
        on: {
          close: function ($event) {
            _vm.dialogVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }