<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="980px"
    :show-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="beforeClose"
    :title="diaLogType ? '提单文件' : '上传附件'"
  >
    <div slot="title" class="el-dialog__title">
      {{ diaLogType ? '提单文件' : '上传附件' }}
      <span v-if="rowData.isPushGGT" class="ggt">已推送GGT</span>
    </div>
    <div class="view">
      <div class="opt">
        <span class="label">上传类型</span>
        <el-cascader
          v-model="type"
          :options="typeOptions"
          style="margin-right: 15px;"
          size="mini"
          placeholder="选择上传类型">
        </el-cascader>
        <span class="label">备注</span>
        <el-input
          v-model="remark"
          style="width: 432px;"
        ></el-input>
        <el-upload
          action="#"
          :show-file-list="false"
          :http-request="httpRequest"
        >
          <div class="upload">
            <img src="@/assets/images/bill/upload-1.png" alt="" />
            选择文件上传
          </div>
        </el-upload>
      </div>
      <div class="data">
        <BaseTable
          :height="diaLogType ? 407 : 359"
          :tableData="tableData"
          :isPager="false"
          :border="true"
        >
          <template slot="type" slot-scope="row">
            {{ row.scope.type | uploadType }}
          </template>
          <template slot="createdOn" slot-scope="row">
            {{ row.scope.createdOn | dateFilete }}
          </template>
          <div slot="opt" slot-scope="row" class="optbtn">
            <div
              class="btn"
              @click="check(row.scope)"
            >
              <i class="el-icon-zoom-in"></i>
              查看
            </div>
            <div class="btn" @click="download(row.scope)">
              <img src="@/assets/images/bill/download.png" alt="" />
              下载
            </div>
          </div>
        </BaseTable>
      </div>
      <div class="footer">*暂时只支持图片和PDF查看</div>
    </div>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { getMBLFile, uploadMBLFile } from '@/api/system'
// import { getMBLSendWay } from '@/api/bill'

import config from '@/utils/config'
export default {
  components: {
    BaseTable
  },
  filters: {
    dateFilete: function(date) {
      if (date) {
        return date.slice(0, date.length - 3)
      } else {
        return ''
      }
    },
    uploadType(num) {
      return config.fileTypeOption.find(a => a.value === num)?.label
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    },
    diaLogType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      type: '',
      tableData: {
        fileds: [
          {
            filed: 'fileName',
            label: '文件名',
            width: '210',
            resizable: true
          },
          {
            filed: 'type',
            label: '上传类型',
            width: '100',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'mblSendWayName',
            label: '派送方式',
            width: '100',
            resizable: true
          },
          {
            filed: 'remark',
            label: '备注',
            width: '100',
            resizable: true
          },
          {
            filed: 'createdOn',
            label: '上传时间',
            width: '150',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'createdName',
            label: '上传人',
            width: '100',
            resizable: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '124',
            isCustom: true,
            resizable: true
          }
        ],
        columns: [],
        total: 0
      },
      options: [
        { label: 'AN', value: 1 },
        { label: 'DO', value: 2 },
        { label: '拆柜清单', value: 12 },
        { label: '其他', value: 99 }
        // { label: 'ISA', value: 3, children: [] },
        // { label: 'POD', value: 4, children: [] },
      ],
      typeOptions: [],
      remark: ''
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        this.type = ''
        this.remark = ''
        this.getList()
        const obj = { label: '税金单', value: 7 }
        this.rowData.subStatus !== 2 && (this.typeOptions = [...this.options, obj])
        this.rowData.subStatus === 2 && (this.typeOptions = this.options)
        this.rowData.subStatus === 4 && (this.typeOptions = [obj])
        // try {
        //   const result = await getMBLSendWay({ mblId: this.rowData.id })
        //   const options = result.data.map(a => {
        //     return {
        //       value: a.id,
        //       label: a.typeName
        //     }
        //   })
        //   this.options[2].children = options
        //   this.options[3].children = options
        // } catch (error) {
        //   console.error(error)
        // }
      }
    }
  },
  methods: {
    beforeClose() {
      this.tableData.columns = []
      this.fileInfo = null
      this.$emit('close')
    },
    async getList() {
      try {
        const result = await getMBLFile({ id: this.rowData.id })
        if (result.success) {
          this.tableData.columns = result.data
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async httpRequest({ file }) {
      console.log(file.type)
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
      const isImage = types.includes(file.type)
      if (!isImage && this.type[0] !== 7) {
        this.$message.error('该类型只能上传图片和PDF文件')
        return false
      }
      const types2 = ['application/pdf']
      const isXlsx = types2.includes(file.type)
      if (!isXlsx && this.type[0] === 7) {
        this.$message.error('该类型只能上传pdf文件')
        return false
      }
      const fileName = file.name
      if (fileName.length > 80) {
        this.$message('文件名字长度不能超过80')
        return
      }
      if (!this.type) {
        this.$message('请先选择上传类型')
        return
      }
      // 正则判断全角字符
      const reg = /[\uff00-\uffff]/g
      if (
        Array.isArray(fileName.match(reg)) &&
        fileName.match(reg).length > 0
      ) {
        this.$message.error('上传失败，不能存在特殊字符')
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      try {
        const params = {
          source: this.type[0],
          id: this.rowData.id,
          remark: this.remark
        }
        this.type[0] === 3 && (params.mblSendWayId = this.type[1] ? this.type[1] : undefined)
        this.type[0] === 4 && (params.mblSendWayId = this.type[1] ? this.type[1] : undefined)
        const result = await uploadMBLFile(
          params,
          formData
        )
        if (result.data.success) {
          this.type[0] === 7 && (this.$emit('ok'))
          this.$message.success('上传成功')
          setTimeout(() => {
            this.getList()
          }, 500)
        } else {
          this.$message.error(result.data.errorMessage)
        }
      } catch (error) {
        this.$message.error('上传失败')
      }
    },
    check(item) {
      const suffix = item.fileName.slice(
        item.fileName.lastIndexOf('.') + 1,
        item.fileName.length
      )
      const { fileGuid } = item
      if (suffix === 'jpg' || suffix === 'jpeg' || suffix === 'png') {
        window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${fileGuid}`)
      } else if (suffix === 'pdf') {
        window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${fileGuid}`)
      } else {
        this.$message('暂时只支持图片和PDF查看')
      }
    },
    download(v) {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/File/downLoadFile?guid=${v.fileGuid}`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    }
  }
}
</script>

<style scoped lang="less">
.view {
  position: relative;
  width: calc(100% - 40px);
  margin: 0 auto;
  .opt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    margin-bottom: 16px;
    .upload {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 32px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #2395f3;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      margin-left: 20px;
      box-sizing: border-box;
      cursor: pointer;
      img {
        margin-right: 4px;
      }
    }
    .label {
      display: inline-block;
      width: auto;
      margin-right: 5px;
    }
  }
  .data {
    border-radius: 6px 6px 6px 6px;
    overflow: hidden;
    &:deep(.fTable) {
      .fp-cell {
        border: 0;
      }
    }
  }
  &:deep(.el-select),
  &:deep(.el-input) {
    input {
      height: 34px;
      line-height: 34px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    color: #CDCDCD;
  }
  .optbtn {
    display: flex;
    justify-content: space-between;
  }
  .btn {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    cursor: pointer;
    img {
      margin-right: 4px;
    }
    i {
      font-size: 18px;
      margin-right: 4px;
    }
  }
}
.ggt {
  width: auto;
  height: 24px;
  background: rgba(157, 103, 254, .1);
  border-radius: 4px 4px 4px 4px;
  padding: 4px 5px;
  box-sizing: border-box;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #9D67FE;
}
</style>
