var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        visible: _vm.dialogVisible,
        width: "980px",
        "show-close": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.beforeClose,
        title: _vm.diaLogType ? "提单文件" : "上传附件",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "el-dialog__title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _vm._v(" " + _vm._s(_vm.diaLogType ? "提单文件" : "上传附件") + " "),
          _vm.rowData.isPushGGT
            ? _c("span", { staticClass: "ggt" }, [_vm._v("已推送GGT")])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "view" }, [
        _c(
          "div",
          { staticClass: "opt" },
          [
            _c("span", { staticClass: "label" }, [_vm._v("上传类型")]),
            _c("el-cascader", {
              staticStyle: { "margin-right": "15px" },
              attrs: {
                options: _vm.typeOptions,
                size: "mini",
                placeholder: "选择上传类型",
              },
              model: {
                value: _vm.type,
                callback: function ($$v) {
                  _vm.type = $$v
                },
                expression: "type",
              },
            }),
            _c("span", { staticClass: "label" }, [_vm._v("备注")]),
            _c("el-input", {
              staticStyle: { width: "432px" },
              model: {
                value: _vm.remark,
                callback: function ($$v) {
                  _vm.remark = $$v
                },
                expression: "remark",
              },
            }),
            _c(
              "el-upload",
              {
                attrs: {
                  action: "#",
                  "show-file-list": false,
                  "http-request": _vm.httpRequest,
                },
              },
              [
                _c("div", { staticClass: "upload" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/bill/upload-1.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" 选择文件上传 "),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c("BaseTable", {
              attrs: {
                height: _vm.diaLogType ? 407 : 359,
                tableData: _vm.tableData,
                isPager: false,
                border: true,
              },
              scopedSlots: _vm._u([
                {
                  key: "type",
                  fn: function (row) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("uploadType")(row.scope.type)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "createdOn",
                  fn: function (row) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("dateFilete")(row.scope.createdOn)) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "opt",
                  fn: function (row) {
                    return _c("div", { staticClass: "optbtn" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.check(row.scope)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-zoom-in" }),
                          _vm._v(" 查看 "),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.download(row.scope)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/bill/download.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" 下载 "),
                        ]
                      ),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("div", { staticClass: "footer" }, [
          _vm._v("*暂时只支持图片和PDF查看"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }