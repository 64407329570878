<template>
  <div>
    <Drawer :drawer="drawer" :withHeader="false" :size="size" @close="close">
      <div class="view">
        <div v-if="!showTrack && !showIsf && !showOrderDetail">
          <div class="tag">
            <el-tabs v-model="active" type="card" @tab-click="handleClick">
              <el-tab-pane label="基本信息" name="0"></el-tab-pane>
              <el-tab-pane
                v-if="rowData.status !== 0 && mblDetail.clearenceType"
                label="清关"
                name="1"
              ></el-tab-pane>
              <el-tab-pane
                v-if="rowData.status !== 0 && rowData.status !== 1 && mblDetail.pickUpType"
                label="提拆交"
                name="2"
              ></el-tab-pane>
              <el-tab-pane label="订单信息" name="3"></el-tab-pane>
            </el-tabs>
            <i class="el-icon-close closeIcon" @click="close" />
          </div>
          <div v-if="active === '0'" class="panel panelActive1">
            <div class="panel2">
              <div v-if="!billType" class="panel-title">
                <svg-icon
                  iconClass="panel-icon"
                  className="icon"
                  :style="{ 'font-size': 20 }"
                />
                基本信息
              </div>
              <el-row>
                <el-col :span="24">
                  <div class="field">
                    <label>{{ billType ? "航空公司" : "船公司" }}</label>
                    <p>{{ mblDetail.transportName }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>主单号</label>
                    <p v-tooltip="155">{{ mblDetail.mblNo }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>目的国家</label>
                    <p>{{ mblDetail.countryCode }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>ETD</label>
                    <p>{{ mblDetail.etd | dateFilete }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>ETA</label>
                    <p>{{ mblDetail.eta | dateFilete }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>ATD</label>
                    <p>{{ mblDetail.atd | dateFilete }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>ATA</label>
                    <p>{{ mblDetail.ata | dateFilete }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>{{ billType ? "始发机场" : "始发地" }}</label>
                    <p v-tooltip="155">{{ mblDetail.originHarborName }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>{{ billType ? "目的机场" : "目的地" }}</label>
                    <p v-tooltip="155">{{ mblDetail.destinationHarborName }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>清关方式</label>
                    <p style="color: #2395f3">
                      <span
                        class="type"
                        style="background: rgba(35, 149, 243, 0.1)"
                      >
                        {{ mblDetail.clearenceType ? "平台清" : "自清" }}
                      </span>
                      <span v-if="mblDetail.clearenceType">{{
                        mblDetail.clearenceSupply?.name
                      }}</span>
                    </p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>提货方式</label>
                    <p style="color: #9d67fe">
                      <span
                        class="type"
                        style="background: rgba(157, 103, 254, 0.1)"
                      >
                        {{
                          mblDetail.pickUpType
                            ? mblDetail.pickUpType === 1
                              ? "直送"
                              : "回仓"
                            : "自提"
                        }}
                      </span>
                      <span v-if="mblDetail.pickUpType">{{
                        mblDetail.pickUpSupply?.name
                      }}</span>
                    </p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>重量</label>
                    <p>{{ mblDetail.weightKgs }} KG</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>方数</label>
                    <p>{{ mblDetail.weightCbm }} CBM</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>提单件数</label>
                    <p>{{ mblDetail.qty }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>订单件数</label>
                    <p>{{ mblDetail.btns }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="mblDetail?.pickUp?.clearenceSupplierName">
                <div class="field">
                  <label>清关供应商</label>
                  <p>{{ mblDetail?.pickUp?.clearenceSupplierName }}</p>
                </div>
              </el-row>
              <el-row v-if="mblDetail?.pickUp?.pickUpSupplierName">
                <div class="field">
                  <label>提货供应商</label>
                  <p>{{ mblDetail?.pickUp?.pickUpSupplierName }}</p>
                </div>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="field">
                    <label>发货人/Shipper</label>
                    <div class="field-content">
                      <div class="field-c_top">
                        <div
                          v-if="shipperNameOptions?.companyName"
                          class="field3"
                        >
                          <span>公司名</span>
                          <span>{{ shipperNameOptions?.companyName }}</span>
                        </div>
                      </div>
                      <div class="field-c_top">
                        <div
                          v-if="shipperNameOptions?.taxNumber"
                          class="field3"
                        >
                          <span>税号</span>
                          <span>{{ shipperNameOptions?.taxNumber }}</span>
                        </div>
                      </div>
                      <div class="field-c_bottom">
                        <p>
                          {{ shipperNameOptions?.addressLine1 | formatStatusAddress }}
                          {{ shipperNameOptions?.addressLine2 | formatStatusAddress }}
                          {{ shipperNameOptions?.state | formatStatusAddress }}
                          {{ shipperNameOptions?.city | formatStatusAddress }}
                          {{ shipperNameOptions?.countryCode | formatStatusAddress }}
                          <strong>{{ shipperNameOptions?.postalCode ? shipperNameOptions?.postalCode : '' }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="field">
                    <label>收货人/Consignee</label>
                    <div class="field-content">
                      <div class="field-c_top">
                        <div
                          v-if="consigneeNameOptions?.companyName"
                          class="field3"
                        >
                          <span>公司名</span>
                          <span>{{ consigneeNameOptions?.companyName }}</span>
                        </div>
                      </div>
                      <div class="field-c_top">
                        <div
                          v-if="consigneeNameOptions?.taxNumber"
                          class="field3"
                        >
                          <span>税号</span>
                          <span>{{ consigneeNameOptions?.taxNumber }}</span>
                        </div>
                      </div>
                      <div class="field-c_bottom">
                        <p>
                          {{ consigneeNameOptions?.addressLine1 | formatStatusAddress }}
                          {{ consigneeNameOptions?.addressLine2 | formatStatusAddress }}
                          {{ consigneeNameOptions?.state | formatStatusAddress }}
                          {{ consigneeNameOptions?.city | formatStatusAddress }}
                          {{ consigneeNameOptions?.countryCode | formatStatusAddress }}
                          <strong>{{ consigneeNameOptions?.postalCode ? consigneeNameOptions?.postalCode : '' }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>提单类型</label>
                    <p>
                      {{
                        mblDetail.classification
                          ? "分单"
                          : "直单"
                      }}
                    </p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>提单文件</label>
                    <p>
                      <span class="check_file" @click="checkBillFile">
                        <i class="el-icon-search"></i>查看提单文件
                      </span>
                    </p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>提单状态</label>
                    <p>
                      <span class="bill-status">{{
                        config.billShipSubtabOptions[mblDetail.status]?.find(
                          (a) => a.value == mblDetail.subStatus
                        )?.label
                      }}</span>
                    </p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>确认时间</label>
                    <p>{{ mblDetail.entryDate | dateFilete }}</p>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-if="!billType" class="panel2">
              <div class="panel-title">
                <svg-icon
                  iconClass="panel-icon"
                  className="icon"
                  :style="{ 'font-size': 20 }"
                />
                柜子信息
              </div>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>柜号</label>
                    <p v-tooltip="155">{{ mblDetail.container?.containerNumber }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>柜型</label>
                    <p v-tooltip="155">{{ mblDetail.container?.containertype }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>船名</label>
                    <p v-tooltip="155">{{ mblDetail.container?.vesselName }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>船次/航次</label>
                    <p v-tooltip="155">{{ mblDetail.container?.voyageNo }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>航线</label>
                    <p v-tooltip="155">{{ mblDetail.container?.routeLine }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>封条号</label>
                    <p v-tooltip="155">{{ mblDetail.container?.sealNumber }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="field">
                    <label>卸船日期</label>
                    <p>{{ mblDetail.container?.dischargeDate | dateFilete }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="field">
                    <label>可提柜日期</label>
                    <p>{{ mblDetail.container?.availableDate | dateFilete }}</p>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="field">
                    <label>还空柜日期</label>
                    <p>{{ mblDetail.container?.emptyReturnedDate | dateFilete }}</p>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div
              v-if="mblDetail.status !== 0"
              class="panel-btn"
              @click="viewDetails('track')"
            >
              <img src="@/assets/images/bill/track.png" />
              提单轨迹
            </div>
            <div v-else-if="mblDetail.clearenceType !== 0 && mblDetail.status === 0 && billType === 0" class="panel-btn" @click="viewDetails('isf')">
              <img src="@/assets/images/bill/isf.png" />
              ISF信息
            </div>
          </div>
          <div v-if="active === '1'" class="panel panelActive2">
            <el-row>
              <el-col :span="12">
                <div class="field">
                  <label>接收DO邮箱</label>
                  <p v-tooltip="190">{{ mblDetail?.clearence?.receivedDoEmail }}</p>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="field">
                  <label>接收DO公司名称</label>
                  <p v-tooltip="190">{{ mblDetail?.clearence?.receivedDoCompany }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="field">
                  <label>放行时间</label>
                  <p>{{ mblDetail?.clearence?.releaseDate }}</p>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="field">
                  <label>是否查验</label>
                  <p>{{ mblDetail?.clearence?.isHold ? '是' : '否' }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="field">
                  <label>查验原因</label>
                  <p v-tooltip="510">{{ mblDetail?.clearence?.holdReason }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="field">
                  <label>DO</label>
                  <p>{{ mblDetail?.clearence?.doDate | dateFilete }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="field">
                  <label>AN</label>
                  <p>{{ mblDetail?.clearence?.anDate | dateFilete }}</p>
                </div>
              </el-col>
            </el-row>
            <div v-if="billType === 0" class="panel-btn" @click="viewDetails('isf')">
              <img src="@/assets/images/bill/isf.png" />
              ISF信息
            </div>
          </div>
          <div v-if="active === '2'" class="panel panelActive3">
            <div class="panel2">
              <div class="panel-title">
                <svg-icon
                  iconClass="panel-icon"
                  className="icon"
                  :style="{ 'font-size': 20 }"
                />
                提货信息
              </div>
              <div class="panle-container">
                <el-row>
                  <!-- <el-col :span="12">
                    <div class="field">
                      <label>预约时间</label>
                      <p>{{ mblDetail?.pickUp?.picExpactDate | dateFilete }}</p>
                    </div>
                  </el-col> -->
                  <el-col :span="12">
                    <div class="field">
                      <label>预约提货时间</label>
                      <p>
                        {{ mblDetail?.pickUp?.picExpactPickUpDate | dateFilete }}
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="field">
                      <label>提货时间</label>
                      <p>{{ mblDetail?.pickUp?.picPickUpDate | dateFilete }}</p>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="field">
                      <label>状态</label>
                      <p>
                        <span class="status">{{
                        config.billShipSubtabOptions[mblDetail.status]?.find(
                          (a) => a.value == mblDetail.subStatus
                        )?.label
                        }}</span>
                      </p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="panel2">
              <div class="panel-title">
                <svg-icon
                  iconClass="panel-icon"
                  className="icon"
                  :style="{ 'font-size': 20 }"
                />
                到仓信息
              </div>
              <div class="panle-container">
                <el-row>
                  <el-col :span="12">
                    <div class="field">
                      <label>到仓时间</label>
                      <p>
                        {{ mblDetail?.pickUp?.picArrivedDate | dateFilete }}
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="field">
                      <label>首次扫描时间</label>
                      <p>
                        {{
                          mblDetail?.pickUp?.picFirstArrivedScanDate
                            | dateFilete
                        }}
                      </p>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="field">
                      <label>到仓件数</label>
                      <p>
                        <span class="markbox">
                          <span class="mark-label green">已到</span>
                          <span class="mark-info">{{ mblDetail?.pickUp?.arrivedQty }}</span>
                        </span>
                        <span class="markbox">
                          <span class="mark-label red">未到</span>
                          <span class="mark-info">{{ mblDetail?.pickUp?.notArrivedQty }}</span>
                        </span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="field">
                      <label>扫描完成时间</label>
                      <p>
                        {{
                          mblDetail?.pickUp?.picLastArrivedScanDate | dateFilete
                        }}
                      </p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="panel2">
              <div class="panel-title">
                <svg-icon
                  iconClass="panel-icon"
                  className="icon"
                  :style="{ 'font-size': 20 }"
                />
                出仓信息
              </div>
              <div class="panle-container">
                <el-row>
                  <el-col :span="12">
                    <div class="field">
                      <label>出仓板数</label>
                      <p>{{ mblDetail?.pickUp?.notDepaturedQty }}</p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="field">
                      <label>首次扫描时间</label>
                      <p>
                        {{
                          mblDetail?.pickUp?.picFirstDepaturedScanDate
                            | dateFilete
                        }}
                      </p>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="field">
                      <label>出仓件数</label>
                      <p>
                        <span class="markbox">
                          <span class="mark-label green">已出</span>
                          <span class="mark-info">{{ mblDetail?.pickUp?.depaturedQty }}</span>
                        </span>
                        <span class="markbox">
                          <span class="mark-label red">未出</span>
                          <span class="mark-info">{{ mblDetail?.pickUp?.notDepaturedQty }}</span>
                        </span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="field">
                      <label>扫描完成时间</label>
                      <p>
                        {{
                          mblDetail?.pickUp?.picLastDepaturedcanDate
                            | dateFilete
                        }}
                      </p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div>
              <div class="panel-title">
                <svg-icon
                  iconClass="panel-icon"
                  className="icon"
                  :style="{ 'font-size': 20 }"
                />
                派送方式
              </div>
              <div class="panle-container panel3">
                <BaseTable
                  :height="280"
                  :tableData="tableData1"
                  :isPager="false"
                  :border="true"
                >
                  <template slot="opt" slot-scope="row">
                    <div class="table-opt">
                      <el-popover placement="left" width="300" trigger="hover">
                        <div class="view-detail">
                          <div
                            v-for="(item, $index) in row.scope.eventItems"
                            :key="$index"
                            class="eventItems"
                          >
                            <p>{{ item.eventDate }}</p>
                            <p>{{ item.title }} {{ item.description }}</p>
                          </div>
                        </div>
                        <img
                          slot="reference"
                          style="cursor: pointer"
                          src="@/assets/images/bill/detail.png"
                        />
                      </el-popover>
                    </div>
                  </template>
                  <template slot="remarks" slot-scope="row">
                    <el-popover
                      placement="bottom-end"
                      width="440"
                      trigger="hover">
                      <div class="remarks-box">
                        <div class="remarks-header">
                          <span>备注内容</span>
                          <span @click="handleCopy(row.scope.remarks)"><img src="@/assets/images/order/copy3.png">复制</span>
                        </div>
                        <div class="remarks-body">
                          {{ row.scope.remarks }}
                        </div>
                      </div>
                      <img slot="reference" style="cursor: pointer;" src="@/assets/images/bill/detail.png">
                    </el-popover>
                  </template>
                </BaseTable>
              </div>
            </div>
          </div>
          <div v-if="active === '3'" class="panel panelActive4">
            <BaseTable
              :height="600"
              :tableData="tableData2"
              :pageIndex="pager2.pageIndex"
              :pageSize="pager2.pageSize"
              :isPager="true"
              :border="true"
              @handlePageChange="handlePageChange"
            >
              <template slot="opt" slot-scope="row">
                <div v-if="row.scope.expressSupplier !== 'Other'" class="opt-btn" @click="detail(row.scope)">
                  <img src="@/assets/images/bill/detail.png" alt="" />
                  详情
                </div>
              </template>
            </BaseTable>
          </div>
        </div>
        <div v-if="showTrack || showIsf || showOrderDetail" class="back">
          <div class="title">
            <div class="before" @click="back">
              <img src="@/assets/images/bill/back.png" alt="">
              <span>{{ showOrderDetail ? "返回订单详情" : "返回详情" }}</span>
            </div>
            <div class="current">
              <img src="@/assets/images/bill/arrow.png" alt="">
              <span>{{ (showIsf && 'ISF信息') || (showTrack && '提单轨迹') || (showOrderDetail && '订单详情') }}</span>
            </div>
          </div>
          <i class="el-icon-close closeIcon" @click="close" />
        </div>
        <div v-if="showTrack" class="log-body">
          <div class="track-num">
            提单号：<span class="number">{{ mblDetail.blNo }}</span>
          </div>
          <div class="track-other">
            <div class="country">
              <div class="country-code">
                <span>{{ mblDetail.originHarborName.split('-')[0] }}</span>
                <span>{{ mblDetail.originHarborName.split('-')[1] }}</span>
              </div>
              <img src="@/assets/images/order/guide.png" />
              <div class="country-code">
                <span>{{ mblDetail.destinationHarborName.split('-')[0] }}</span>
                <span>{{ mblDetail.destinationHarborName.split('-')[1] }}</span>
              </div>
            </div>
            <div class="navication flex_c_c">
              当前状态：<span>{{
                config.billShipSubtabOptions[mblDetail.status]?.find(
                  (a) => a.value == mblDetail.subStatus
                )?.label
              }}</span>
            </div>
          </div>
          <div class="track-step">
            <div v-for="(item, index) in trackList" :key="index" class="step-item">
              <div
                :class="['step-title', index === 0 ? 'blue' :
                  item.descriptionCH.indexOf('海关查验') > -1 ||
                  item.statusCode === 'HOLD_SYS' ? 'check' : '']">
                <div class="dot">
                  <div
                    :class="['step-dot', index === 0 ? 'blue-dot'
                      :
                      item.descriptionCH.indexOf('海关查验') > -1 ||
                      item.statusCode === 'HOLD_SYS' ? 'check-dot' : '']"
                  ></div>
                </div>
                <div class="step-time">
                  {{ item.eventDate }}&nbsp;&nbsp;{{ item.location }}
                </div>
              </div>
              <div
                :class="['step-info', index === 0 ? 'blue' :
                  item.descriptionCH.indexOf('海关查验') > -1 ||
                  item.statusCode === 'HOLD_SYS' ? 'check' : '']">
                <span>{{ item.descriptionCH }}</span>
                <template v-if="item.isOpen && item.outboundDetails">
                  <div v-for="(a, i) in item.outboundDetails" :key="i" class="step-detail">
                    <div class="step-detail_item">
                      <span class="detail-label1">派送方式</span>
                      <span class="detail-info">{{ a?.typeName }}</span>
                    </div>
                    <el-row>
                      <el-col :span="10">
                        <div class="step-detail_item">
                          <span class="detail-label1">总件数</span>
                          <span class="detail-info">{{ a?.qty }}</span>
                        </div>
                      </el-col>
                      <el-col :span="14">
                        <div class="step-detail_item">
                          <span class="detail-label2">ISP</span>
                          <span class="detail-info">{{ a?.ispFile?.uploadDate ? a?.ispFile?.uploadDate : '无' }}</span>
                          <div v-if="a?.ispFile?.fileGuid" class="viewFile flex_c_c" @click="viewFile(a?.ispFile?.fileGuid)">查看</div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="10">
                        <div class="step-detail_item">
                          <span class="detail-label1">出仓</span>
                          <span class="detail-info">{{ a?.departureQty }}</span>
                        </div>
                      </el-col>
                      <el-col :span="14">
                        <div class="step-detail_item">
                          <span class="detail-label2">POD</span>
                          <span class="detail-info">{{ a?.podFile?.uploadDate ? a?.podFile?.uploadDate : '无' }}</span>
                          <div v-if="a?.podFile?.fileGuid" class="viewFile flex_c_c" @click="viewFile(a?.podFile?.fileGuid)">查看</div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </div>
              <div v-if="index !== trackList.length - 1" :class="['step-line', , index === 0 ? 'blue-line' : '']"></div>
              <i
                v-if="item.outboundDetails && item.outboundDetails.length"
                :class="[item.isOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down', 'arrow']"
                :title="item.isOpen ? '收起' : '展开'"
                @click="isOepn(index)"
              ></i>
            </div>
          </div>
        </div>
        <div v-if="showIsf" class="log-body">
          <div class="isf_info">
            <div class="row-item">
              <div class="item-label">OnBoard Date</div>
              <div class="item-data">
                {{ mblDetail?.clearence?.isf?.onBoardDate | dateFilete }}
              </div>
            </div>
            <div class="row-item">
              <div class="item-label">AMS SCAC</div>
              <div class="item-data">{{ mblDetail?.clearence?.isf?.amsscac }}</div>
            </div>
            <div class="row-item">
              <div class="item-label">AMS No.</div>
              <div class="item-data">{{ mblDetail?.clearence?.isf?.amsblNumber }}</div>
            </div>
            <div class="row-item">
              <div class="item-label">Importer Bond Type</div>
              <div class="item-data">{{ config.bondTypeOptions.find(
                (a) => a.value === mblDetail?.clearence?.isf?.bondType
              )?.label }}</div>
            </div>
            <div class="row-item">
              <div class="item-label">Bill Number Type</div>
              <div class="item-data">{{ mblDetail?.clearence?.isf?.classification ? '分单' : '直单' }}</div>
            </div>
            <div v-for="(item, $index) in addressRowArr" :key="$index" class="row-item">
              <div class="item-label">{{ item.label1 }}<br>{{ item.nameCH }}</div>
              <div class="address">
                <div v-if="item.label1 !== 'Importer'" class="conpanyName">
                  <span>公司名</span>
                  <span>{{ mblDetail?.clearence?.isf?.[item.label2] }}</span>
                </div>
                <div v-else style="display: flex;">
                  <div class="conpanyName">
                    <span>公司名</span>
                    <span>{{ mblDetail?.clearence?.isf?.[item.label2] }}</span>
                  </div>
                  <div class="conpanyName">
                    <span>EIN</span>
                    <span>{{ addressDetail[item.label2 + 'Address']?.taxNumber }}</span>
                  </div>
                </div>
                <p>
                  {{
                    addressDetail[item.label2 + 'Address']?.addressLine1 | formatStatusAddress
                  }}
                  {{
                    addressDetail[item.label2 + 'Address']?.addressLine2 | formatStatusAddress
                  }}
                  {{ addressDetail[item.label2 + 'Address']?.city | formatStatusAddress }}
                  {{ addressDetail[item.label2 + 'Address']?.state | formatStatusAddress }}
                  {{
                    addressDetail[item.label2 + 'Address']?.countryCode | formatStatusAddress
                  }}
                  <strong>{{
                    addressDetail[item.label2 + 'Address']?.postalCode
                        ? addressDetail[item.label2 + 'Address']?.postalCode
                        : ""
                  }}</strong>
                </p>
              </div>
            </div>
          </div>
          <div class="panle-container">
            <div class="table-header">+HS CODE列表</div>
            <BaseTable
              height="133"
              :tableData="tableData3"
              :isPager="false"
              :border="true"
            ></BaseTable>
          </div>
        </div>
        <div v-if="showOrderDetail" class="log-body orderActive">
          <div class="panle-container3">
            <div class="panle-title">基本信息</div>
            <el-row>
              <el-col :span="8">
                <div class="field2">
                  <label>订单编号</label>
                  <p>{{ order.id }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>客户单号</label>
                  <p style="color: #2395f3" class="orderNo">
                    <span>{{ order.referenceNumber }}</span>
                    <img style="cursor: pointer;" src="@/assets/images/order/copy2.png" title="复制" @click="handleCopy(order.referenceNumber)" />
                  </p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>追踪单号</label>
                  <p style="color: #2395f3" class="orderNo">
                    <span>{{ order.trackingNumber }}</span>
                    <img style="cursor: pointer;" src="@/assets/images/order/copy2.png" title="复制" @click="handleCopy(order.trackingNumber)" />
                  </p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="field2">
                  <label>供应商</label>
                  <p>{{ $store.state.supply }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>件数</label>
                  <p>{{ order.qty }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>状态</label>
                  <span class="order-status">{{ order.status | formatStatus }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="field2">
                  <label>实重</label>
                  <p>{{ order.actWeightKG }} KG</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>材积重</label>
                  <p>{{ order.volumeWeightKG }} KG</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>计费重</label>
                  <p>{{ order.weightKG }} KG</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="field2">
                  <label>费用</label>
                  <p>{{ order.estimateAmount }} {{ order.currency }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>国家</label>
                  <p>{{ formatterCountryCode(order.originCountryCode) }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>派送方式</label>
                  <p style="color: #2395f3;font-weight: bold;">{{ order.expressSupplier }} {{ order.expressService }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="field2">
                  <label>创建时间</label>
                  <p>{{ order.createdOn }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>上传时间</label>
                  <p>{{ order.uploadOn }}</p>
                </div>
              </el-col>
              <el-col v-if="order.fbaId || order.fbaRefNo" :span="8">
                <div class="field">
                  <label>FBA信息</label>
                  <p>
                    <span v-if="order.fbaId">{{ order.fbaId }}</span>
                    <span v-if="order.fbaId && order.fbaRefNo" style="margin: 0 10px;">|</span>
                    <span v-if="order.fbaRefNo">{{ order.fbaRefNo }}</span>
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="panle-container3">
            <div class="panle-title">收件方信息</div>
            <el-row>
              <el-col :span="24">
                <div class="field2">
                  <label>公司名称</label>
                  <p>{{ order?.address?.companyName }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16">
                <div class="field2">
                  <label>收件人</label>
                  <p>{{ order?.address?.attentionName }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>类型</label>
                  <p>收件人</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="field2">
                  <label>国家</label>
                  <p>{{ formatterCountryCode(order?.address?.countryCode) }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>省州</label>
                  <p>{{ order?.address?.state }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>城市</label>
                  <p>{{ order?.address?.city }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="field2">
                  <label>邮编</label>
                  <p>{{ order?.address?.postalCode }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>电话</label>
                  <p>{{ order?.address?.tel }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="field2">
                  <label>邮箱</label>
                  <p>{{ order?.address?.email }}</p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="field2">
                  <label>详细地址一</label>
                  <p>
                    <span class="label flex_c_c">{{ order?.address?.classificationName }}</span>
                    {{ order?.address?.addressLine1 }}
                  </p>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="field2">
                  <label>详细地址二</label>
                  <p>{{ order?.address?.addressLine2 }}</p>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="type2">
            <div
              :class="{ active: orderActive === 0, btn: true }"
              @click="changeType(0)"
            >
              包裹信息
            </div>
            <div
              :class="{ active: orderActive === 1, btn: true }"
              @click="changeType(1)"
            >
              产品信息
            </div>
          </div>
          <div class="data2">
            <BaseTable
              v-show="orderActive"
              height="320"
              :tableData="tableData5"
              :pageIndex="pager2.pageIndex"
              :pageSize="pager2.pageSize"
              :loading="loading"
              :isPager="true"
              @handlePageChange="handlePageChange"
            ></BaseTable>
            <BaseTable
              v-show="!orderActive"
              height="320"
              :tableData="tableData4"
              :pageIndex="pager2.pageIndex"
              :pageSize="pager2.pageSize"
              :loading="loading"
              :isPager="true"
              @handlePageChange="handlePageChange"
            >
              <template slot="productURL" slot-scope="row">
                <i class="el-icon-link" @click="openUrl(row.scope.productURL)" />
              </template>
            </BaseTable>
          </div>
        </div>
      </div>
    </Drawer>
    <UploadAttachmentDialog
      :dialogVisible="dialogVisible"
      :diaLogType="1"
      :rowData="rowData"
      @close="dialogVisible = false"
    ></UploadAttachmentDialog>
  </div>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
const BaseTable = () => import('@/components/table/BaseTable')
import UploadAttachmentDialog from './UploadAttachmentDialog.vue'
import { getMBLDetail, getOrderDetailPages, getMBLTrackList } from '@/api/bill'
import {
  // trackDetail,
  getOrderDetail,
  getOrderDimensionPageList,
  getOrderProductPageList
} from '@/api/fee'
import config from '@/utils/config'
import { mapGetters } from 'vuex'
export default {
  components: { Drawer, BaseTable, UploadAttachmentDialog },
  filters: {
    dateFilete: function(date) {
      if (date) {
        const newDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(date)
        return newDate[0]
      } else {
        return ''
      }
    },
    formatStatus: function(value) {
      if (value || value === 0) {
        const item = config.orderStatusOptions.find(
          (a) => parseInt(a.value, 10) === value
        )
        return item.label
      } else {
        return ''
      }
    },
    formatType: function(value) {
      if (value) {
        const arr = [
          '私仓',
          '订单收件地址',
          '发货/件方',
          '收货/件方',
          '进口商',
          '买家',
          '卖家',
          '制造商',
          '拼货方',
          '货物送达方',
          '装货地'
        ]
        return arr.find((a, i) => i === value)
      } else {
        return ''
      }
    },
    formatStatusAddress(val) {
      return val ? val + ',' : ''
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    billType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      config,
      active: '0',
      // pager: {
      //   pageIndex: 1,
      //   pageSize: 1000
      // },
      pager2: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      size: 720,
      showTrack: false,
      showIsf: false,
      showOrderDetail: false,
      dialogVisible: false,
      tableData1: {
        fileds: [
          {
            filed: 'typeName',
            label: '派送类型',
            formatter: row =>
              row.code ? `${row.typeName}-${row.code}` : row.typeName,
            width: ''
            // width: '120'
          },
          // {
          //   filed: 'type',
          //   label: '派送方式',
          //   width: '120',
          //   formatter: row => {
          //     return config.sendWayOptions.find(a => a.value === row.type).label
          //   }
          // },
          {
            filed: 'qty',
            label: '件数',
            width: '79'
          },
          {
            filed: 'arrivalQty',
            label: '到仓',
            width: '79'
          },
          {
            filed: 'departureQty',
            label: '出仓',
            width: '79'
          },
          {
            filed: 'departureCbm',
            label: '板数',
            width: '79'
          },
          {
            filed: 'remarks',
            label: '备注',
            isCustom: true,
            width: '79'
          }
        ],
        columns: [],
        total: 0
      },
      tableData2: {
        fileds: [
          {
            filed: 'referenceNumber',
            label: '客户单号',
            width: '153',
            isCopy: true
          },
          {
            filed: 'masterTrackingNumber',
            label: '主单号',
            width: '153',
            isCopy: true
          },
          {
            filed: 'trackingNumber',
            label: '子单号',
            width: '153',
            isCopy: true
          },
          {
            filed: 'expressSupplier',
            label: '派送方式',
            width: '80'
          },
          {
            filed: 'opt',
            label: '操作',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      tableData3: {
        fileds: [
          {
            filed: 'hsCode',
            label: '海关编码',
            width: '140'
          },
          {
            filed: 'originCountryCode',
            label: '原产地国家二字代码',
            width: '140'
          },
          {
            filed: 'nameEN',
            label: '英文描述'
          }
        ],
        columns: [],
        total: 0
      },
      tableData4: {
        fileds: [
          {
            filed: 'packageNumber',
            label: '包裹编号',
            width: '80'
          },
          {
            filed: 'trackingNumber',
            label: '追踪单号',
            width: '180',
            isCopy: true
          },
          {
            filed: 'agentLength',
            label: '长',
            width: ''
          },
          {
            filed: 'agentWidth',
            label: '宽',
            width: ''
          },
          {
            filed: 'agentHeight',
            label: '高',
            width: ''
          },
          {
            filed: 'agentDimensionUnit',
            label: '尺寸单位',
            width: ''
          },
          {
            filed: 'agentWeight',
            label: '实重',
            width: ''
          },
          {
            filed: 'calculateVolumeWeight',
            label: '材积重',
            width: ''
          },
          {
            filed: 'calculateWeight',
            label: '计费重量',
            width: ''
          },
          {
            filed: 'agentWeightUnit',
            label: '重量单位',
            width: ''
          },
          {
            filed: 'labelRefNo',
            label: '参考号',
            width: '140'
          }
        ],
        columns: [],
        total: 0
      },
      tableData5: {
        fileds: [
          {
            filed: 'nameEN',
            label: '英文品名',
            width: '140'
          },
          {
            filed: 'nameCH',
            label: '中文品名',
            width: '120'
          },
          {
            filed: 'hsCode',
            label: 'HS CODE',
            width: '100'
          },
          {
            filed: 'qty',
            label: '数量',
            width: ''
          },
          {
            filed: 'unitPrice',
            label: '产品价值',
            width: ''
          },
          {
            filed: 'currency',
            label: '币制',
            width: ''
          },
          {
            filed: 'unitWeight',
            label: '产品重量',
            width: ''
          },
          {
            filed: 'weightUnit',
            label: '重量单位',
            width: ''
          },
          {
            filed: 'sku',
            label: 'SKU',
            width: ''
          },
          {
            filed: 'material',
            label: '材质',
            width: ''
          },
          {
            filed: 'used',
            label: '用途',
            width: ''
          },
          {
            filed: 'productURL',
            label: '产品链接',
            width: '',
            isCustom: true
          },
          {
            filed: 'packageUnit',
            label: '计费单位',
            width: ''
          }
        ],
        columns: [],
        total: 0
      },
      mblDetail: {},
      order: {}, // 订单详情信息
      orderActive: 0,
      trackList: [],
      addressRowArr: [
        { label1: 'Consignee', label2: 'consigneeName', nameCH: '收货人' },
        { label1: 'Importer', label2: 'importerName', nameCH: '进口商' },
        { label1: 'Buyer', label2: 'buyerName', nameCH: '买方' },
        { label1: 'Selling Party', label2: 'sellingName', nameCH: '卖方' },
        { label1: 'Ship To Party', label2: 'shipToPartyName', nameCH: '收货公司' },
        { label1: 'Manufacture', label2: 'manufacturerName', nameCH: '制造商' },
        { label1: 'Consolidator', label2: 'consolidatorName', nameCH: '拼箱地' },
        { label1: 'Container Staffing Location', label2: 'stuffingName', nameCH: '装柜地' }
      ]
    }
  },
  computed: {
    ...mapGetters(['addressDropOption', 'addressBookOptions']),
    shipperNameOptions() {
      const shipperNameOptions = this.addressBookOptions.filter(
        (a) => a.type === 20 && a.companyName === this.mblDetail.shipperName
      )
      return shipperNameOptions[0]
    },
    consigneeNameOptions() {
      const addressBookOptions = this.addressBookOptions.filter(
        (a) => a.type === 20 && a.companyName === this.mblDetail.consigneeName
      )
      return addressBookOptions[0]
    },
    addressDetail() {
        const item = this.mblDetail?.clearence?.isf || {}
        const importerNameAddress = this.addressBookOptions.find(a => a.companyName === item.importerName)
        const manufacturerNameAddress = this.addressBookOptions.find(a => a.companyName === item.manufacturerName)
        const sellingNameAddress = this.addressBookOptions.find(a => a.companyName === item.sellingName)
        const buyerNameAddress = this.addressBookOptions.find(a => a.companyName === item.buyerName)
        const shipToPartyNameAddress = this.addressBookOptions.find(a => a.companyName === item.shipToPartyName)
        const consolidatorNameAddress = this.addressBookOptions.find(a => a.companyName === item.consolidatorName)
        const stuffingNameAddress = this.addressBookOptions.find(a => a.companyName === item.stuffingName)
        const consigneeNameAddress = this.addressBookOptions.find(a => a.companyName === item.consigneeName)
        return {
          importerNameAddress,
          manufacturerNameAddress,
          sellingNameAddress,
          buyerNameAddress,
          shipToPartyNameAddress,
          consolidatorNameAddress,
          stuffingNameAddress,
          consigneeNameAddress
        }
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.size = 720
        this.active = '0'
        this.orderActive = 0
        this.showTrack = false
        this.showIsf = false
        this.showOrderDetail = false
        this.pager2 = {
          pageIndex: 1,
          pageSize: 20
        }
        this.tableData1.columns = []
        this.tableData2.columns = []
        this.tableData3.columns = []
        this.tableData4.columns = []
        this.tableData5.columns = []
        this.getMblDetail()
        this.$store.dispatch('getAddressBookOptions', { type: -1, companyId: this.rowData.companyId })
      }
    }
  },
  async mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getMblDetail() {
      try {
        const result = await getMBLDetail({ mblId: this.rowData.id })
        if (result.success) {
          this.mblDetail = result.data
          this.tableData1.columns = result.data.pickUp?.sendWayItems
          this.tableData3.columns = result.data.clearence?.isf?.htsItems?.map(
            (a) => {
              return {
                hsCode: a.hsCode,
                nameEN: a.nameEN,
                originCountryCode: a.countryCode
              }
            }
          )
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (erroe) {
        console.error(erroe)
      }
    },
    async getList2(params) {
      try {
        params = {
          ...params,
          queryParam: {
            id: this.rowData.id
          }
        }
        const result = await getOrderDetailPages(params)
        if (result.success) {
          this.tableData2.columns = result.data.list
          this.tableData2.total = result.data.total
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (erroe) {
        console.error(erroe)
      }
    },
    async getTrackDetail() {
      try {
        const result = await getMBLTrackList({ mblId: this.rowData.id })
        if (result.success) {
          this.trackList = result.data.map(a => {
            a.eventDate = /\d{4}-\d{1,2}-\d{1,2}[ ]\d{1,2}:\d{1,2}/g.exec(a.eventDate)[0]
            a.isOpen = true
            return a
          })
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (erroe) {
        console.error(erroe)
      }
    },
    handleClick() {
      this.active === '3' && this.getList2(this.pager2)
    },
    viewDetails(v) {
      if (v === 'track') {
        this.size = 520
        this.showTrack = true
        this.getTrackDetail()
      } else {
        this.showIsf = true
      }
    },
    back() {
      this.size = 720
      this.showTrack = false
      this.showIsf = false
      this.showOrderDetail = false
    },
    detail(row) {
      this.size = 1076
      this.showOrderDetail = true
      this.orderId = row.masterOrderId
      this.getOrder()
      this.getPackageList(this.pager2)
    },
    changeType(type) {
      this.orderActive = type
      this.pager2 = {
        pageIndex: 1,
        pageSize: 20
      }
      type && this.getProductList(this.pager2)
      !type && this.getPackageList(this.pager2)
    },
    handlePageChange(pager) {
      this.pager2 = pager
      if (this.active === '3' && !this.showOrderDetail) {
        this.getList2(pager)
      }
      if (this.orderActive && this.showOrderDetail) {
        this.getProductList(pager)
      }
      if (!this.orderActive && this.showOrderDetail) {
        this.getPackageList(pager)
      }
    },
    async getOrder() {
      try {
        const res = await getOrderDetail({
          orderId: this.orderId
        })
        if (res.success) {
          this.order = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
        console.log(res)
      } catch (error) {
        console.error(error)
      }
    },
    async getPackageList(params) {
      try {
        params = {
          ...params,
          queryParam: {
            orderId: this.orderId
          }
        }
        const res = await getOrderDimensionPageList(params)
        if (res.success) {
          this.tableData4.columns = res.data.list
          this.tableData4.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getProductList(params) {
      try {
        params = {
          ...params,
          queryParam: {
            orderId: this.orderId
          }
        }
        const res = await getOrderProductPageList(params)
        if (res.success) {
          this.tableData5.columns = res.data.list
          this.tableData5.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    checkBillFile() {
      this.dialogVisible = true
    },
    formatterCountryCode(code) {
      if (this.addressDropOption && code) {
        const country = this.addressDropOption.find((a) => a.code === code)
        if (country) {
          return `${country.code}-${country.label}`
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    handleCopy(v) {
      const url = v
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      oInput.remove()
    },
    isOepn(index) {
      this.$set(this.trackList[index], 'isOpen', !this.trackList[index].isOpen)
    },
    viewFile(guid) {
      guid && window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${guid}`)
    }
  }
}
</script>

<style lang="less" scoped>
.closeIcon {
  font-size: 20px;
  cursor: pointer;
}
.view {
  width: 100%;
  height: 100%;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  overflow-y: hidden;
  &:deep(.fTable) {
    .fp-cell {
      border: 0;
    }
  }
  &:deep(.el-row + .el-row) {
    margin-top: 8px;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background: #F7F7F7;
    border-radius: 4px;
    font-size: 14px;
    color: #999999;
  }
  .panelActive1 {
    label {
      width: 140px;
      margin-right: 20px;
    }
  }
  .panelActive2,
  .panelActive3 {
    label {
      width: 120px;
      margin-right: 10px;
    }
  }
  .orderActive {
    label {
      width: 100px;
      height: 30px;
      background: #FFFFFF;
      margin-right: 15px;
    }
  }
  .data {
    width: calc(100% - 80px);
    height: 400px;
    margin: 16px auto;
  }
  .data2 {
    width: 100%;
    height: 400px;
    margin: 15px auto;
  }
  .table-opt {
    display: flex;
    justify-content: space-around;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #F7F7F7;
    padding-right: 20px;
    box-sizing: border-box;
    &:deep(.el-tabs__header) {
      margin: 15px 0;
    }
    &:deep(.el-tabs--card > .el-tabs__header) {
      border-bottom: 0;
    }
    &:deep(.el-tabs__nav) {
      border: 0;
    }
    &:deep(.el-tabs__item) {
      height: 24px;
      line-height: 24px;
      border: 0;
    }
    &:deep(.el-tabs__item.is-active) {
      color: #2395f3;
    }
  }
  .panle-container {
    width: 100%;
    height: auto;
    border-radius: 6px 6px 6px 6px;
    .table-header {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #F7F7F7;
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #EEEEEE;
      border-bottom: 0;
      box-sizing: border-box;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .markbox {
      display: inline-block;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      .mark-label {
        width: auto;
        height: 24px;
        border-radius: 4px 4px 4px 4px;
        margin-right: 4px;
        padding: 0 6px;
      }
      .mark-info {
        color: #333333;
      }
      .green {
        color: #35BA83;
        background: rgba(53, 186, 131, .1);
      }
      .red {
        color: #FE453D;
        background: rgba(254, 69, 61, .1);
      }
      & + .markbox {
        margin-left: 24px;
      }
    }
  }
  .panel {
    width: 100%;
    height: calc(100vh - 120px);
    margin: 20px auto 0;
    padding: 0 40px 20px;
    box-sizing: border-box;
    overflow-y: auto;
    .panel-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom: 17px;
      svg {
        margin-right: 8px;
      }
    }
  }
  .panel2 {
    margin-bottom: 24px;
  }
  .panel3 {
    width: 100%;
  }
  .field,
  .field2 {
    width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .field {
    p {
      flex: 1;
      height: 30px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
      box-sizing: border-box;
      line-height: 30px;
    }
  }
  .field-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - 164px);
    min-height: 80px;
    height: auto;
    .field-c_top {
      display: flex;
      width: 100px;
      margin-bottom: 4px;
      .field3 {
        display: flex;
        align-items: center;
        margin-right: 16px;
        & > span:first-child {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 56px;
          height: 24px;
          border-radius: 4px 4px 4px 4px;
          background: rgba(53, 186, 131, 0.1);
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #35ba83;
          margin-right: 4px;
        }
        & > span:last-child {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .field-c_bottom {
      width: 100%;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      word-break: break-all;
      white-space: break-spaces;
      p {
        font-size: 12px;
        height: auto;
        line-height: normal;
        text-overflow: clip;
        word-wrap: break-word;
        word-break: break-all;
        white-space: break-spaces;
      }
    }
  }
  .field2 {
    align-items: center;
    p {
      flex: 1;
      height: 32px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 6px;
      box-sizing: border-box;
    }
  }
  .panle-container2 {
    width: calc(100% - 48px);
    height: auto;
    padding: 24px;
    border-radius: 6px 6px 6px 6px;
    background: #ffffff;
    border: 1px solid #f2f7ff;
    margin-bottom: 32px;
    label {
      width: 100px;
    }
  }
  .panle-container3 {
    width: calc(100% - 40px);
    height: auto;
    padding: 20px 15px;
    border-radius: 4px 4px 4px 4px;
    background: #FBFBFB;
    margin-bottom: 20px;
    .panle-title {
      height: 21px;
      font-size: 16px;
      font-weight: 400;
      color: #2395F3;
      line-height: 21px;
      margin-left: 10px;
      margin-bottom: 20px;
    }
    p {
      display: flex;
      align-items: center;
      width: auto;
      height: 32px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .label {
      width: 40px;
      height: 24px;
      background: rgba(35, 149, 243, .1);
      border-radius: 4px 4px 4px 4px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395F3;
      margin-right: 5px;
    }
  }
  .check_file {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 24px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    i {
      font-size: 14px;
      margin-right: 4px;
    }
  }
  .bill-status {
    height: 24px;
    text-align: center;
    background: rgba(254, 69, 61, 0.1);
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #fe453d;
    padding: 3px 10px;
    box-sizing: border-box;
  }
  .status {
    display: inline-block;
    width: 64px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: rgba(53, 186, 131, 0.1);
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #35ba83;
  }
  .order-status {
    display: inline-block;
    width: auto;
    height: 26px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 26px;
    color: #35ba83;
    background-color: rgba(53, 186, 131, 0.1);
    padding: 0 12px;
    border-radius: 4px 4px 4px 4px;
  }
  .type {
    display: inline-block;
    width: 64px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px 4px 4px 4px;
    margin-right: 4px;
  }
  .type2 {
    display: flex;
    height: 40px;
    border-radius: 4px;
    width: 410px;
    background: #F7F7F7;
    border: 1px solid #EEEEEE;
    margin: 40px auto 0;
    padding: 5px;
    box-sizing: border-box;
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    .active {
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.05);
      color: #2395F3;
    }
  }
  .type_text {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }
  .panel-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #2395f3;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    cursor: pointer;
    margin: 40px auto 0;
    img {
      margin-right: 8px;
    }
  }
  .opt-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    cursor: pointer;
    img {
      margin-right: 4px;
    }
  }
}
.back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #f2f7ff;
  padding-left: 12px;
  padding-right: 20px;
  box-sizing: border-box;
  .title {
    display: flex;
  }
  .before {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395F3;
    cursor: pointer;
  }
  .current {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  span {
    margin-left: 8px;
  }
}
.log-body {
  width: 100%;
  height: calc(100vh - 120px);
  margin: 20px auto 0;
  padding: 0 20px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  .isf_info {
    width: 100%;
    margin-bottom: 24px;
    &:deep(.el-row + .el-row) {
      margin-top: 0;
    }
    .row-item {
      display: flex;
      width: 100%;
      height: auto;
      margin-bottom: 8px;
    }
    .item-label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      background: #F7F7F7;
      border-radius: 4px 4px 4px 4px;
      min-height: 30px;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-right: 20px;
    }
    .item-data {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      height: auto;
      min-height: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .address {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex: 1;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      height: auto;
      min-height: 80px;
      .conpanyName {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        & span:first-child {
          display: inline-block;
          width: 48px;
          height: 24px;
          line-height: 24px;
          background: rgba(53, 186, 131, 0.1);
          border-radius: 4px 4px 4px 4px;
          color: #35ba83;
          text-align: center;
        }
        & span:last-child {
          color: #333333;
          margin-left: 5px;
        }
        & + .conpanyName {
          margin-left: 5px;
        }
      }
      p {
        color: #666666;
        word-wrap: break-word;
        word-break: break-all;
        white-space: break-spaces;
      }
    }
  }
  .track-num {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #F7F7F7;
    border-radius: 6px 6px 6px 6px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    .number {
      color: #2395f3;
    }
  }
  .track-other {
    display: flex;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    .country {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 292px;
      height: 100%;
      background: #F7F7F7;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      margin-right: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #2395f3;
      img {
        margin: 0 10px;
      }
      .country-code {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 120px;
        & span:last-child {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .navication {
      width: 200px;
      height: 100%;
      background: #F7F7F7;
      color: #333333;
      border-radius: 6px 6px 6px 6px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      span {
        color: #2395F3;
      }
    }
  }
  .track-step {
    width: 100%;
    height: auto;
    min-height: 438px;
    background: #FBFBFB;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-top: 10px;
    padding: 22px 0 0 20px;
    box-sizing: border-box;
    .step-item {
      position: relative;
      height: auto;
      color: #999999;
      .step-title {
        display: flex;
        align-items: center;
        position: relative;
        height: 16px;
        .dot {
          display: flex;
          justify-content: center;
          width: 12px;
        }
        .step-dot {
          width: 8px;
          height: 8px;
          background: #cdcdcd;
          border-radius: 50px;
        }
        .blue-dot {
          width: 12px;
          height: 12px;
          background: #2395f3;
        }
        .check-dot {
          width: 12px;
          height: 12px;
          background: #FF7444;
          z-index: 1;
        }
        .step-time {
          position: absolute;
          left: 26px;
          width: calc(100% - 55px);
          font-size: 12px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .step-info {
        font-size: 14px;
        font-weight: bold;
        color: #999999;
        line-height: 19px;
        margin: 8px 0 0 26px;
        padding-bottom: 24px;
        box-sizing: border-box;
        .step-detail {
          width: 414px;
          height: 78px;
          background: rgba(255, 255, 255, .8);
          border-radius: 6px 6px 6px 6px;
          margin-top: 8px;
          padding: 10px;
          border: 1px solid #EEEEEE;
          box-sizing: border-box;
        }
        .el-row {
          margin-top: 5px;
        }
        .step-detail_item {
          display: flex;
          align-items: center;
          height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
          font-weight: 300;
          color: #999999;
        }
        .detail-label1 {
          display: inline-block;
          width: 48px;
          margin-right: 10px;
        }
        .detail-label2 {
          width: 26px;
          display: inline-block;
          margin-right: 10px;
        }
        .detail-info {
          color: #333333;
          font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          font-weight: 400;
        }
        .viewFile {
          width: 34px;
          height: 16px;
          background: #2395F3;
          border-radius: 8px 8px 8px 8px;
          color: #F7F7F7;
          font-size: 12px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
      .step-line {
        position: absolute;
        top: 10px;
        left: 6px;
        height: 100%;
        border-left: 1px solid #cdcdcd;
      }
      .blue {
        color: #2395f3;
      }
      .check {
        color: #FF7444;
      }
      .blue-line {
        border-left: 1px dashed #2395f3;
      }
      .arrow {
        position: absolute;
        right: 24px;
        top: 0;
        font-size: large;
        color: #2395F3;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="less">
.el-popper {
  .view-detail {
    width: 100%;
    max-height: 240px;
    height: auto;
    overflow-y: scroll;
  }
  .eventItems {
    width: 100%;
    padding: 8px;
    background: #f8fbff;
    border-radius: 6px 6px 6px 6px;
    box-sizing: border-box;
  }
}
.remarks-box {
  width: 100%;
  height: 240px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  overflow: hidden;
  .remarks-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #F7F7F7;
    border-bottom: 1px solid #EEEEEE;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    & span:nth-child(2) {
      color: #2395F3;
      cursor: pointer;
    }
  }
  .remarks-body {
    width: 100%;
    height: calc(100% - 40px);
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    overflow-y: auto;
  }
}
</style>
